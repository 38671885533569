





































































.modal__form-text {
  margin-bottom: 32px;
  font-size: 16px;
}
