





































































































































































































































































































































.modal::v-deep {
  .modal__wrapper {
    max-width: 836px;
    width: 100%;
  }

  h2 {
    margin: 12px 0;
  }

  .scroll-wrapper {
    max-height: 400px;
    overflow-y: scroll;
    padding-right: 10px;
    margin-bottom: 20px;

    /* width */
    &::-webkit-scrollbar {
      width: 8px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #0059A3;
      border-radius: 3px;
    }
  }
  
  .document {
    display: flex;
    align-items: center;
    
    &-add {
      background-color: #0059A3;
      border-radius: 99px;
      padding: 5px;
      color: white;
      margin-left: 10px;
      cursor: pointer;
      
      &:hover {
        background-color: #046dc6;
      }
    }
    
    &-del {
      background-color: #0059A3;
      border-radius: 99px;
      padding: 5px;
      color: white;
      cursor: pointer;
      margin-top: 12px;
      
      &:hover {
        background-color: #046dc6;
      }
    }
  }
}
